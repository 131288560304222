.c-timeline {
    border-left-width: 2px;
    border-left-style: solid;
    margin-left: calc(1rem - 2px);
    padding-left: 25px;
    position: relative;
}

.c-timeline__item {
    position: relative;
}

.c-timeline__point {
    height: 10px;
    width: 10px;
    position: absolute;
    left: -31px;
    top: 3px;
    border-radius: 50%;
}

.c-timeline__end {
    height: 1px;
    width: 16px;
    position: absolute;
    left: -9px;
    bottom: 0;
    border-width: 1px;
    border-style: solid;
}