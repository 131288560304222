.c-progress-bar {
    position: relative;
    height: .4rem;
    
    
    //@extend margin-bottom
}

.c-progress-bar__filler {
    height: 100%;
}