html {
    font-size                : $base-font-size;
    min-height               : 100%;
    -webkit-text-size-adjust : 100%;
    -ms-text-size-adjust     : 100%;
    -moz-osx-font-smoothing  : grayscale;
    -webkit-font-smoothing   : antialiased;
}
body {
    font-size              : $base-font-size;
    font-family            : $font-family-base;
    font-weight            : 400;
    -webkit-font-smoothing : subpixel-antialiased;
    line-height            : $base-line-height;
    color                  : $base-color;
    background             : $base-bg;
}
html,
body {
    height : 100%;
}

