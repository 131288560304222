$grid-namespace : $objects-namespace !default;

$grid-columns : 12 !default;
$grid-gutter-x : $spacer-x !default;
$grid-gutter-y : $spacer-y !default;

// column default style
[class*="#{$grid-namespace}grid__col"] {
    width         : 100%;
    margin-bottom : $grid-gutter-y;
}
.#{$grid-namespace}grid {
    position     : relative;
    flex-flow    : row wrap !important;
    display      : flex;
    //padding-right : -($grid-gutter-x);
}



@each $breakpoint in map_keys($media-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        @for $column from 1 through $grid-columns {
            .#{$grid-namespace}grid__col-#{$breakpoint}-#{$column} {
                $fraction : ($column / $grid-columns);
                flex  : 0 0 auto;
                width : calc(99.999999% * #{$fraction});
            }
        }
    }
}

@include media-breakpoint-up(xs){
    .o-grid {
        [class*="#{$grid-namespace}grid__col"] {
            margin-bottom : 3rem;
            position: relative;
            padding-right : 0;
        }
    }
}


@include media-breakpoint-up(md){
    [class*="#{$grid-namespace}grid__col"] {
        padding-right : $grid-gutter-x;
    }
    .c-header__contact {
        text-align: left;
    }
}

@include media-breakpoint-up(sm) {
    .#{$grid-namespace}grid {
        flex-flow : row nowrap !important;
    }
    .o-grid {
        [class*="#{$grid-namespace}grid__col"] {
            margin-bottom : 5rem;
            position: relative;
        }
    }
}