html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

[class*="o-grid__col"] {
  width: 100%;
  margin-bottom: 0; }

.o-grid {
  position: relative;
  flex-flow: row wrap !important;
  display: flex; }

.o-grid__col-xs-1 {
  flex: 0 0 auto;
  width: calc(99.999999% * 0.08333); }

.o-grid__col-xs-2 {
  flex: 0 0 auto;
  width: calc(99.999999% * 0.16667); }

.o-grid__col-xs-3 {
  flex: 0 0 auto;
  width: calc(99.999999% * 0.25); }

.o-grid__col-xs-4 {
  flex: 0 0 auto;
  width: calc(99.999999% * 0.33333); }

.o-grid__col-xs-5 {
  flex: 0 0 auto;
  width: calc(99.999999% * 0.41667); }

.o-grid__col-xs-6 {
  flex: 0 0 auto;
  width: calc(99.999999% * 0.5); }

.o-grid__col-xs-7 {
  flex: 0 0 auto;
  width: calc(99.999999% * 0.58333); }

.o-grid__col-xs-8 {
  flex: 0 0 auto;
  width: calc(99.999999% * 0.66667); }

.o-grid__col-xs-9 {
  flex: 0 0 auto;
  width: calc(99.999999% * 0.75); }

.o-grid__col-xs-10 {
  flex: 0 0 auto;
  width: calc(99.999999% * 0.83333); }

.o-grid__col-xs-11 {
  flex: 0 0 auto;
  width: calc(99.999999% * 0.91667); }

.o-grid__col-xs-12 {
  flex: 0 0 auto;
  width: calc(99.999999% * 1); }

@media (min-width: 544px) {
  .o-grid__col-sm-1 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.08333); }
  .o-grid__col-sm-2 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.16667); }
  .o-grid__col-sm-3 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.25); }
  .o-grid__col-sm-4 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.33333); }
  .o-grid__col-sm-5 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.41667); }
  .o-grid__col-sm-6 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.5); }
  .o-grid__col-sm-7 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.58333); }
  .o-grid__col-sm-8 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.66667); }
  .o-grid__col-sm-9 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.75); }
  .o-grid__col-sm-10 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.83333); }
  .o-grid__col-sm-11 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.91667); }
  .o-grid__col-sm-12 {
    flex: 0 0 auto;
    width: calc(99.999999% * 1); } }

@media (min-width: 768px) {
  .o-grid__col-md-1 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.08333); }
  .o-grid__col-md-2 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.16667); }
  .o-grid__col-md-3 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.25); }
  .o-grid__col-md-4 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.33333); }
  .o-grid__col-md-5 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.41667); }
  .o-grid__col-md-6 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.5); }
  .o-grid__col-md-7 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.58333); }
  .o-grid__col-md-8 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.66667); }
  .o-grid__col-md-9 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.75); }
  .o-grid__col-md-10 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.83333); }
  .o-grid__col-md-11 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.91667); }
  .o-grid__col-md-12 {
    flex: 0 0 auto;
    width: calc(99.999999% * 1); } }

@media (min-width: 992px) {
  .o-grid__col-lg-1 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.08333); }
  .o-grid__col-lg-2 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.16667); }
  .o-grid__col-lg-3 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.25); }
  .o-grid__col-lg-4 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.33333); }
  .o-grid__col-lg-5 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.41667); }
  .o-grid__col-lg-6 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.5); }
  .o-grid__col-lg-7 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.58333); }
  .o-grid__col-lg-8 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.66667); }
  .o-grid__col-lg-9 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.75); }
  .o-grid__col-lg-10 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.83333); }
  .o-grid__col-lg-11 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.91667); }
  .o-grid__col-lg-12 {
    flex: 0 0 auto;
    width: calc(99.999999% * 1); } }

@media (min-width: 1200px) {
  .o-grid__col-xl-1 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.08333); }
  .o-grid__col-xl-2 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.16667); }
  .o-grid__col-xl-3 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.25); }
  .o-grid__col-xl-4 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.33333); }
  .o-grid__col-xl-5 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.41667); }
  .o-grid__col-xl-6 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.5); }
  .o-grid__col-xl-7 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.58333); }
  .o-grid__col-xl-8 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.66667); }
  .o-grid__col-xl-9 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.75); }
  .o-grid__col-xl-10 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.83333); }
  .o-grid__col-xl-11 {
    flex: 0 0 auto;
    width: calc(99.999999% * 0.91667); }
  .o-grid__col-xl-12 {
    flex: 0 0 auto;
    width: calc(99.999999% * 1); } }

.o-grid [class*="o-grid__col"] {
  margin-bottom: 3rem;
  position: relative;
  padding-right: 0; }

@media (min-width: 768px) {
  [class*="o-grid__col"] {
    padding-right: 3.5rem; }
  .c-header__contact {
    text-align: left; } }

@media (min-width: 544px) {
  .o-grid {
    flex-flow: row nowrap !important; }
  .o-grid [class*="o-grid__col"] {
    margin-bottom: 5rem;
    position: relative; } }

form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.o-list-bare,
.c-social-buttons {
  margin-left: 0;
  padding: 0;
  list-style: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Muli, Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    font-weight: 400;
    font-size: .7em; }

h2,
h3 {
  font-size: 1.2rem;
  line-height: 1.45;
  text-transform: uppercase; }

.o-container {
  max-width: 1440px;
  margin: 0 auto; }

.o-section__header-bg,
.o-section__content-bg {
  display: none; }

.o-section__header {
  padding: 1.75rem 1.75rem 0rem; }

.o-section__content {
  padding: 1.75rem 1.75rem 0rem; }

.o-section__full-top-space {
  padding-top: 1.75rem; }

.o-section__full-bottom-space {
  padding-bottom: 1.75rem; }

@media (min-width: 768px) {
  .o-section__header {
    padding: 3.15rem 3.5rem 1.4rem; }
  .o-section--header .o-section__header {
    padding-bottom: 0; }
  .o-section__content {
    padding: 3.15rem 3.5rem 1.4rem; }
  .o-section__full-top-space {
    padding-top: 3.15rem; }
  .o-section__full-bottom-space {
    padding-bottom: 3.15rem; } }

@media (min-width: 992px) {
  .o-section {
    position: relative; }
  .o-section__container {
    z-index: 2;
    position: relative;
    display: flex; }
  .o-section__header {
    width: 30%;
    text-align: right; }
  .o-section__content {
    width: 70%; }
  .o-section__header-bg,
  .o-section__content-bg {
    display: block;
    position: absolute;
    height: 100%;
    width: 50%;
    z-index: 1; }
  .o-section__content-bg {
    right: 0; } }

@media (min-width: 1200px) {
  .o-section__header {
    padding: 4.375rem 5.25rem 1.6625rem; }
  .o-section__content {
    padding: 4.375rem 5.25rem 1.6625rem; }
  .o-section__full-top-space {
    padding-top: 4.375rem; }
  .o-section__full-bottom-space {
    padding-bottom: 4.375rem; } }

.c-header__top,
.c-header__brand,
.c-header__contact-divider {
  margin-bottom: 1.75rem; }

.c-header__brand,
.c-header__avatar,
.c-header__contact {
  text-align: center; }

.c-header__social-buttons {
  justify-content: center; }

@media (min-width: 768px) {
  .c-header__contact {
    text-align: left; } }

@media (min-width: 992px) {
  .c-header__header,
  .c-header__contact {
    height: 100vh; }
  .c-header__inner-header,
  .c-header__inner-content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .c-header__avatar,
  .c-header__top {
    top: -2vh;
    position: relative; }
  .c-header__avatar {
    margin-top: -5.8rem;
    text-align: right;
    position: relative; }
  .c-header__brand {
    text-align: left;
    margin-bottom: 0; }
  .c-header__social-buttons {
    position: absolute;
    bottom: 0;
    right: 0; }
  .c-header__contact {
    position: absolute;
    width: 100%;
    bottom: -90%;
    left: 0; } }

.o-content__body,
.o-content__body :last-child {
  margin-bottom: 0; }

ul,
ol,
dd {
  margin-left: 3.5rem;
  padding-left: 0; }

ul {
  list-style: none;
  line-height: normal; }

html {
  font-size: 1rem;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

body {
  font-size: 1rem;
  font-family: Muli, Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 1.75;
  color: #333;
  background: #F2F2F2; }

html,
body {
  height: 100%; }

a,
a :hover {
  text-decoration: none; }

hr {
  height: 1px;
  color: #CCC;
  background: #CCC;
  font-size: 0;
  border: 0; }

.c-avatar__img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 11rem; }

@media (min-width: 768px) {
  .c-avatar__img {
    width: 14rem; } }

@media (min-width: 1200px) {
  .c-avatar__img {
    width: 15rem; } }

.c-brand__title,
.c-brand__sub-title {
  font-family: Muli, Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  line-height: 1.75;
  line-break: auto;
  word-break: normal;
  word-spacing: normal;
  letter-spacing: normal;
  white-space: normal; }

.c-brand__first-word,
.c-brand__second-word {
  display: block; }

.c-brand__sub-title {
  margin-bottom: 0; }

.c-brand__sizer {
  font-size: 65%; }

@media (min-width: 1200px) {
  .c-brand__title {
    margin-bottom: 1.4rem; }
  .c-brand__sizer {
    font-size: 100%; }
  .c-brand__sizer--sm {
    font-size: 80%; } }

@media (min-width: 992px) {
  .c-brand__title {
    margin-bottom: .8rem; } }

.o-media {
  display: flex;
  align-items: flex-start; }

.o-media__figure {
  margin-right: 1.75rem; }

.o-media__body {
  position: relative;
  flex: 1; }
  .o-media__body,
  .o-media__body :last-child {
    margin-bottom: 0; }
  .o-media__body h3 {
    margin-top: .2rem; }

.o-media--flush .o-media__figure {
  margin-right: 0;
  margin-left: 0; }

.o-media--block > .o-media__figure {
  margin-left: 0; }

@media (min-width: 544px) {
  .o-grid {
    flex-flow: row wrap !important; } }

.fab {
  font-size: 1.5rem;
  line-height: .75rem;
  vertical-align: -15%;
  transform: #dddddd; }

.c-social-button {
  display: block;
  text-align: center;
  width: 33px;
  height: 33px;
  font-size: .8rem !important;
  line-height: 2.7 !important;
  border-radius: 2px;
  border: 2px solid; }

@media (min-width: 1200px) {
  .c-social-button {
    width: 38px;
    height: 38px;
    font-size: 1rem !important;
    line-height: 2.6 !important;
    border-radius: 3px; } }

.c-social-buttons {
  display: flex; }
  .c-social-buttons li {
    margin-right: 6px; }
    .c-social-buttons li:last-child {
      margin-right: 0; }

.c-progress-bar {
  position: relative;
  height: .4rem; }

.c-progress-bar__filler {
  height: 100%; }

.c-timeline {
  border-left-width: 2px;
  border-left-style: solid;
  margin-left: calc(1rem - 2px);
  padding-left: 25px;
  position: relative; }

.c-timeline__item {
  position: relative; }

.c-timeline__point {
  height: 10px;
  width: 10px;
  position: absolute;
  left: -31px;
  top: 3px;
  border-radius: 50%; }

.c-timeline__end {
  height: 1px;
  width: 16px;
  position: absolute;
  left: -9px;
  bottom: 0;
  border-width: 1px;
  border-style: solid; }

.o-articles__items {
  flex-flow: row;
  display: flex; }

o-nav__container {
  background-color: transparent !important; }

.o-nav__top-nav {
  border-bottom: 2px solid rgba(51, 149, 255, 0.15); }
  .o-nav__top-nav ul {
    display: flex;
    justify-content: flex-end; }
    .o-nav__top-nav ul li:not(:last-child) {
      margin-right: 2.625rem; }
    .o-nav__top-nav ul li:last-child {
      margin-right: 5.25rem; }

input {
  width: 100%;
  border: none;
  padding-left: 10px;
  line-height: 1.75;
  font-size: 1.2rem; }

.o-login__container {
  border-radius: 3px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.o-login__title {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .o-login__title .o-login__remember {
    font-size: 0.73rem;
    text-transform: lowercase; }

.o-login__input-block {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .o-login__input-block label {
    padding-left: 10px; }
  .o-login__input-block input:-webkit-autofill,
  .o-login__input-block input:-webkit-autofill:hover,
  .o-login__input-block input:-webkit-autofill:focus,
  .o-login__input-block input:-webkit-autofill,
  .o-login__input-block textarea:-webkit-autofill,
  .o-login__input-block textarea:-webkit-autofill:hover,
  .o-login__input-block textarea:-webkit-autofill:focus,
  .o-login__input-block select:-webkit-autofill,
  .o-login__input-block select:-webkit-autofill:hover,
  .o-login__input-block select:-webkit-autofill:focus {
    border: 0;
    -webkit-text-fill-color: #CCC;
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent;
    border-bottom: 1px solid rgba(204, 204, 204, 0.2); }

.o-login__submit .o-login__input-wrapper {
  text-align: center; }
  .o-login__submit .o-login__input-wrapper input {
    border: none;
    text-transform: uppercase;
    padding: 0.7rem 0; }

.o-login__container {
  flex-direction: column;
  width: 90%; }

.o-login__title {
  padding-bottom: 1.75rem; }

.o-login__input-block {
  padding: 0 1.75rem; }
  .o-login__input-block .o-login__input-wrapper {
    margin: 1.225rem 0; }

@media (min-width: 768px) {
  .o-nav {
    position: inherit; }
  .o-login__container {
    width: inherit; }
  .o-login__container {
    margin-top: 0; } }

@media (min-width: 1200px) {
  .o-login__container {
    width: calc(100vw / 2.5);
    min-height: calc(100vw / 4);
    flex-direction: row-reverse; }
  .o-login__title {
    width: 35%;
    padding-bottom: 3.5rem; }
  .o-login__input-block {
    padding: 1.75rem 1.75rem; }
    .o-login__input-block .o-login__input-wrapper {
      margin: 1.75rem 0; }
  .o-login__content {
    width: 65%; } }
