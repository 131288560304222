@function breakpoint-min($name, $breakpoints: $media-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
}


@mixin media-breakpoint-up($name, $breakpoints: $media-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}