// colors
$base-color : #333 !default;
$base-bg : #F2F2F2 !default;
$base-border-color : #CCC !default;
$base-primary-font-color: #3395ff !default;

// typography
$root-font-size : 16px !default;
$base-line-height : 1.6 !default;
$base-font-size : 1rem !default;
$base-font-weight : 500;

// fonts
$font-family-base : Muli, Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
$heading-font-family : $font-family-base;

$font-size--sm : .8rem;
$font-size--xs : .7rem;

// grid
$grid-columns : 12;

// spacers
$spacer : ($base-font-size * $base-line-height);

$spacer-x : $spacer;
$spacer-y : $spacer;

$spacer-2-x : ($spacer-x * 2);
$spacer-2-y : ($spacer-y * 1.8);

$spacer-3-x : ($spacer-x * 3);
$spacer-3-y : ($spacer-y * 2.5);

$grid-gutter-x : $spacer-2-x;
$grid-gutter-y : 0;

$content-margin-bottom : $spacer-y;
$content-margin-bottom--md : $spacer-y;
$content-margin-bottom--lg : $spacer-y * 1.55;

// Template spacing controls
$section-padding-y:              $spacer-y;
$section-padding-x:              $spacer-x;
$section-padding-bottom:         ($section-padding-y - $content-margin-bottom);
$section-padding:                $section-padding-y  $section-padding-x  $section-padding-bottom;
$section-header-padding:         $section-padding;
$section-content-padding:        $section-padding;

$section-padding-y--md:          $spacer-2-y;
$section-padding-x--md:          $spacer-2-x;
$section-padding-bottom--md:     ($section-padding-y--md - $content-margin-bottom--md);
$section-padding--md:            $section-padding-y--md  $section-padding-x--md  $section-padding-bottom--md;
$section-header-padding--md:     $section-padding--md;
$section-content-padding--md:    $section-padding--md;

$section-padding-y--lg:          $spacer-3-y;
$section-padding-x--lg:          $spacer-3-x;
$section-padding-bottom--lg:     ($section-padding-y--lg - $content-margin-bottom--lg);
$section-padding--lg:            $section-padding-y--lg  $section-padding-x--lg  $section-padding-bottom--lg;
$section-header-padding--lg:     $section-padding--lg;
$section-content-padding--lg:    $section-padding--lg;