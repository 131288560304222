.c-avatar__img {
    -webkit-border-radius : 50%;
    -moz-border-radius    : 50%;
    border-radius         : 50%;
    width                 : 11rem;
}


@include media-breakpoint-up(md){
    .c-avatar__img {
        width: 14rem;
    }
}

@include media-breakpoint-up(xl){
    .c-avatar__img {
        width: 15rem;
    }
}