$media-namespace : $objects-namespace !default;
$media-gutter : $spacer-x !default;

.o-media {
    display     : flex;
    align-items : flex-start;
}
.o-media__figure {
    margin-right : $media-gutter;
}
.o-media__body {
    position: relative;
    flex : 1;
    
    &,
    :last-child {
        margin-bottom : 0;
    }
    h3 {
        margin-top: .2rem;
    }
}
.o-media--flush {
    .o-media__figure {
        margin-right : 0;
        margin-left  : 0;
    }
}
.o-media--block {
    
    > .o-media__figure {
        margin-left: 0;
        //margin-right: 0;
        width: spacer(figure);
    }
    
}


@include media-breakpoint-up(sm) {
    .#{$grid-namespace}grid {
        flex-flow : row wrap !important;
    }
}