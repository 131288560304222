h1, %h1,
h2, %h2,
h3, %h3,
h4, %h4,
h5, %h5,
h6, %h6 {
    font-family : $font-family-base;
    font-weight : 600;
    small {
        font-weight : 400;
        font-size   : .7em;
    }
}
h2, %h2,
h3, %h3 {
    font-size      : 1.2rem;
    line-height    : 1.45;
    text-transform : uppercase;
}