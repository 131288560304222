// reset text
@mixin reset-text {
    font       : {
        family : $font-family-base;
        style  : normal;
        weight : $base-font-weight;
    }
    text           : {
        decoration : none;
        shadow     : none;
        transform  : none;
    }
    line       : {
        height : $base-line-height;
        break  : auto;
    }
    word        : {
        break   : normal;
        spacing : normal;
    }
    letter-spacing : normal;
    white-space    : normal;
}
// centerer

@mixin centerer($horizontal: true, $vertical: true) {
    position : absolute;
    @if ($horizontal and $vertical) {
        top       : 50%;
        left      : 50%;
        transform : translate(-50%, -50%);
    } @else if ($horizontal) {
        left      : 50%;
        transform : translate(-50%, 0);
    } @else if ($vertical) {
        top       : 50%;
        transform : translate(0, -50%);
    }
}
// autofill

@mixin autofill {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border                  : 0;
        -webkit-text-fill-color : $base-border-color;
        -webkit-box-shadow      : 0 0 0 1000px transparent inset;
        transition              : background-color 5000s ease-in-out 0s;
        background-color        : transparent;
        border-bottom           : 1px solid rgba($base-border-color, .2);
    }
}
