// get colors from colors map
@function color($key) {
    @if map-has-key($colors, $key) {
        @return map-get($colors, $key);
    }
    
    @warn "Unknown `#{$key}` in $colors.";
    @return null;
}





// maths helpers.
@function quarter($value) {
    @return $value / 4;
}

@function halve($value) {
    @return $value / 2;
}

@function double($value) {
    @return $value * 2;
}

@function quadruple($value) {
    @return $value * 4;
}