o-nav__container {
    background-color : transparent !important;
}
.o-nav__top-nav {
    
    border-bottom: 2px solid rgba($base-primary-font-color, .15);

    ul {
        display : flex;
        justify-content: flex-end;
        li {
            &:not(:last-child) {
                margin-right: spacer-x(2);
            }
            &:last-child {
                margin-right: spacer-x(3);
            }
        }
        
    }

}