.c-social-buttons {
    @extend %o-list-bare;
    
    display: flex;
    
    li {
        margin-right: 6px;
        
        &:last-child {
            margin-right: 0;
        }
    }
}