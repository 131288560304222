$block-width : 35% !default;

.o-login__container {
    border-radius : 3px;
    display       : flex;
    @include centerer;
}
.o-login__title {
    padding-left    : spacer-x();
    padding-right   : spacer-x();
    text-align      : right;
    display         : flex;
    flex-direction  : column;
    justify-content : flex-end;
    .o-login__remember {
        font-size      : $base-font-size * .73;
        text-transform : lowercase;
    }
}
.o-login__input-block {
    height          : 100%;
    //padding         : spacer-x() spacer-y();
    display         : flex;
    justify-content : center;
    flex-direction  : column;
    .o-login__input-wrapper {
        //margin : spacer-y() 0;
    }
    label {
        padding-left : 10px;
    }
    @include autofill;
}
.o-login__submit {
    .o-login__input-wrapper {
        text-align : center;
        input {
            border         : none;
            text-transform : uppercase;
            padding        : spacer-y() * .4 0;
        }
    }
}
@include media-breakpoint-up(xs) {
    .o-login__container {
        flex-direction : column;
        width          : 90%;
        //margin-top: spacer-y(3);
    }
    .o-login__title {
        padding-bottom : double(spacer-y() * .5);
    }
    .o-login__input-block {
        padding : spacer-x(0) spacer-y();
        .o-login__input-wrapper {
            margin : spacer-y() * .7 0;
        }
    }
}
@include media-breakpoint-up(md) {
    .o-nav {
        position : inherit;
    }
    .o-login__container {
        width : inherit;
    }
    .o-login__container {
        margin-top: 0;
    }
}
@include media-breakpoint-up(xl) {
    .o-login__container {
        width          : calc(100vw / 2.5);
        min-height     : calc(100vw / 4);
        flex-direction : row-reverse;
    }
    .o-login__title {
        width          : $block-width;
        padding-bottom : double(spacer-y());
    }
    .o-login__input-block {
        padding : spacer-x() spacer-y();
        .o-login__input-wrapper {
            margin : spacer-y() 0;
        }
    }
    .o-login__content {
        width : 100% - $block-width;
    }
}
