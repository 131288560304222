$st-spacer : ($base-font-size * $base-line-height) !default;
$st-spacer-x : $st-spacer !default;
$st-spacer-y : $st-spacer !default;

$my-spacers : (
    0: (
        x: 0,
        y: 0
    ),
    1: (
        x: $st-spacer-x,
        y: $st-spacer-y
    ),
    2: (
        x: ($st-spacer-x * 1.5),
        y: ($st-spacer-y * 1.5)
    ),
    3: (
        x: ($st-spacer-x * 3),
        y: ($st-spacer-y * 3)
    ),
    section: (
        x: $spacer-x,
        y: $spacer-y
    ),
    section-md: (
        x: $spacer-2-x,
        y: $spacer-2-y
    ),
    section-lg: (
        x: $spacer-3-x,
        y: $spacer-3-y
    ),
    grid-gutter: (
        x: $grid-gutter-x,
        y: 0
    ),
    gallery-grid-gutter: (
        x: $spacer-x,
        y: $spacer-y
    ),
    content: $content-margin-bottom,
    content-md: $content-margin-bottom--md,
    content-lg: $content-margin-bottom--lg,
    text: .55rem,
    figure: 2.8rem
) !default;

@function spacer($direction, $size:1, $spacers: $my-spacers) {
    @return map-get(map-get($spacers, $size), $direction);
}
@function spacer-x($size:1, $spacers: $my-spacers) {
    @return spacer(x, $size, $spacers);
}
@function spacer-y($size:1, $spacers: $my-spacers) {
    @return spacer(y, $size, $spacers);
}
