@import "settings.responsive";
@import "settings.default";
@import "tools.responsive";
// @import "theme.light";
@import "theme.dark";

body {
    background : $base-bg;
    color      : $text-color;
}
/* Heading */

h1, %h1,
h2, %h2,
h3, %h3,
h4, %h4,
h5, %h5,
h6, %h6 {
    color : $heading-color;
}
/* Link Container */

.t-link-container {
    color   : inherit;
    display : block;
}
.t-link-container:hover,
.t-link-container:focus,
.t-link-container:active {
    color : inherit;
}
.t-link-container .t-link-container__item--blended {
    color : inherit;
}
/* Links */

a,
.t-link-container .t-link-container__item,
.t-link-container:hover .t-link-container__item--blended {
    color : $link-color;
}
/* Hover Links */

a:hover,
a:focus,
a:active,
.t-link-container:hover .t-link-container__item {
    color : $link-color--hover;
}
/* Horizontal Rulers */

hr,
.t-border-color {
    border-color : $base-border-color;
}
hr,
.t-border-color-bg {
    background : $base-border-color;
}
/* Primary Color */

.t-primary-color,
.t-primary-color-line {
    color : $primary-color;
}
.t-primary-bg,
.t-primary-color-line {
    background : $primary-color;
}
.t-preloader__spinner:not(:required):before {
    border-top-color    : $primary-color;
    border-bottom-color : $primary-color;
}
/* Brand Colors */

.t-title__first-word {
    color : $brand-title-first-word-color;
}
.t-title__second-word {
    color : $brand-title-second-word-color;
}
.t-sub-title {
    color : $brand-sub-title-color;
}
/* Brand Typography */

.t-title {
    font-size   : 4.8rem;
    line-height : 0.91;
}
.t-title__first-word {
    text-transform : uppercase;
    font-weight    : 300;
}
.t-title__second-word {
    text-transform : uppercase;
    font-weight    : 700;
}
.t-sub-title {
    font-weight : 300;
    font-size   : 1.5rem;
    margin-left : .3rem;
}
/* Icons */

.fa {
    color : $icon-color;
}
/* Social Buttons */

.t-social-button {
    background   : $social-button-bg;
    border-color : $social-button-bg;

    .fa {
        color : $social-button-color;
    }

    &:hover {
        background : $social-button-bg--hover;

        .fa {
            color : $social-button-color--hover;
        }
    }
}
/* Image Overlay */

.t-image-overlay {
    background : $image-overlay-bg;

    * {
        color : $image-overlay-text-color;
    }

    &:hover img {
        opacity : $image-overlay-hover-opacity;
    }
}
.t-image-overlay__deco-line {
    color      : $image-overlay-deco-line;
    background : $image-overlay-deco-line;
}
/* Transition */

a,
button,
a .fa,
.t-link-container .t-link-container__item,
.t-link-container .t-link-container__item--blended {
    transition : all .15s linear;
}
/* Sections */

.t-section__header {
    background : $section-header-bg--darker;
}
.t-section__content,
.t-section--header .t-section__header {
    background : $section-content-bg;
}
.t-section__content-border-color {
    border-color : $section-content-bg;
}
.t-timeline__point {
    box-shadow : 0 0 0 6px $section-content-bg;
}

/* Login*/
.o-login__container {
    background : darken($base-bg, 2.8%);
}
.o-login__content {
    background : darken($base-bg, 1.2%);
}
.o-login__submit .o-login__input-wrapper {
    background : lighten($base-bg, 30%);
}

/* Input */
input {
    background : transparent;
    border-bottom: 1px solid $base-border-color;
    color: $text-color;
    &:active,
    &:hover,
    &:focus {
        outline: 0;
        outline-offset: 0;
    }
}


@include media-breakpoint-up(xs) {

    .t-title__first-word {
        font-size : 2rem;
    }
    .t-title__second-word {
        font-size : 2.6rem;
    }

}
@include media-breakpoint-up(md) {

    .t-title__first-word {
        font-size : 2.3rem;
    }
    .t-title__second-word {
        font-size : 5rem;
    }

}
@include media-breakpoint-up(lg) {

    /* Odd Sections */

    .t-section,
    .t-section__header,
    .t-section--header .t-section__header {
        background : $section-header-bg;
    }

    .t-section__content {
        background : $section-content-bg;
    }

    .t-title__first-word {
        text-transform : uppercase;
        font-size      : 3.5rem;
        font-weight    : 300;
    }

    /* Even Sections */

    .t-section:nth-child(even) {

        &,
        .t-section__header {
            background : $section-header-bg--even;
        }

        .t-section__content {
            background : $section-content-bg--even;
        }

        .t-timeline__point {
            box-shadow : 0 0 0 6px $section-content-bg--even;
        }

    }

}
