$list-indent-margin: double($spacer-x) !default;

ul,
ol,
dd {
    margin-left: $list-indent-margin;
    padding-left: 0;
}

ul {
    list-style: none;
    line-height: normal;
}