.c-social-button {
    display       : block;
    text-align    : center;
    width         : 33px;
    height        : 33px;
    font-size     : .8rem !important;
    line-height   : 2.7 !important;
    border-radius : 2px;
    border        : 2px solid;
}



@include media-breakpoint-up(xl) {
    .c-social-button {
        width         : 38px;
        height        : 38px;
        font-size     : 1rem !important;
        line-height   : 2.6 !important;
        border-radius : 3px;
    }
}