$list-bare-namespace:    $objects-namespace !default;



.#{$list-bare-namespace}list-bare,
%#{$list-bare-namespace}list-bare {
    margin-left: 0;
    padding: 0;
    list-style: none;
    
}